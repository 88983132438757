import { Link } from 'react-router-dom';
import HEADSHOT from '../assets/img/headshot.jpg';
import FancyBox from '../hooks/Fancybox';
import { config } from '../config';
import Icon from '../components/Icon';

import { vue, javascript, wordpress, html, firebase, photoshop, illustrator, git, cSharp, bootstrap, css, php } from '../assets/img/knowledge';


import { useTitle } from '../hooks/useTitle';
import Certificate from '../components/blocks/Certificate';

function About() {

  useTitle('About');

  return (
    <div className="container">
      <h1 className="heading-alt text-center mb-5 fw-900"> Maker.<br /> Designer.<br /> Developer.</h1>
      <div className="card mt-4">
        <div className="card-body p-4 m-2 large-block">

          <img src={HEADSHOT}
            className="headshot rounded float-none float-md-end m-0 p-0 ms-md-4 mt-md-4 me-md-2 mb-md-5"
            alt="Austin Dudzik headshot" />
          <h3>About Me</h3>

          <p className="my-3 text-muted">I'm Austin, a talented front-end web developer born and raised in Milwaukee. With over 5+ years of self-taught experience in front-end development and design, I've been building and developing a range of websites and web applications since my start in 2017.</p>

          <p className="my-3 text-muted">In 2022, I earned my Associate's Degree in Front End Web Development at Waukesha County Technical College, where I improved upon my pre-existing development skills and expanded my range of knowledge in PHP, JavaScript, and WordPress, along with design and UI in Adobe Photoshop, Illustrator, and XD.</p>

          <p className="my-3 text-muted">Since the start of my development journey, I've developed well over 20 projects ranging from business applications to hobby projects and everything in between, such as <Link to="/projects/campfire" className="gradient-text">Campfire</Link>, an embeddable feedback collection tool, <Link to="/projects/dune" className="gradient-text">Dune</Link>, a full-fledged social media network for Spotify, and <Link to="/projects/nova" className="gradient-text">Nova</Link>, an easy-to-use idea upvote board platform for businesses. My passion for code is ever-growing and my portfolio is always expanding.</p>

          <p className="my-3 text-muted">Currently, I am employed at Snap-on Incorporated as a web developer intern, where I'm in charge of the design, development, and deployment of new internal web applications, using my skill set to improve security, audit code, and implement new features.</p>
        </div>

      </div>

      <p className="small mt-5 mb-3">
        <Icon name="code" className="me-2" /> Languages & Tools
      </p>

      <div className="d-flex flex-wrap align-items-stretch" style={{ columnGap: '15px' }}>

        {[["Vue", vue], ["JavaScript", javascript], ["WordPress", wordpress], ["HTML", html], ["Firebase", firebase], ["Photoshop", photoshop], ["Illustrator", illustrator], ["Git", git], ["C#", cSharp], ["Bootstrap", bootstrap], ["CSS", css], ["PHP", php]
        ].map((lang, index) => (
          <div className="mb-4 flex-grow-1" key={index}>
            <div className="card text-center p-0 px-3">
              <div className="card-body">
                <img src={lang[1]} className="me-2"
                  alt="" width="20" /> {lang[0]}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="text-end">
        <Link to="/skills" className="submit-button text-white bg-transparent me-0 pe-0">
          View all skills <Icon type="far" name="long-arrow-right" className="ms-2" />
        </Link>
      </div>

      <p className="small my-3 mt-5">
        <Icon name="graduation-cap" className="ms-2" /> Education
      </p>

      <div className="bg-gradient p-1 rounded">
        <div className="card rounded">
          <div className="card-body p-4 mx-2 my-3">
            <h3 className="mt-0">Front End Web Development</h3>
            <div className="row">
              <div className="col-12 col-md-8">
                <p className="small text-muted mb-2 mb-md-4">AAS / Waukesha County Technical College - GPA:
                  4.0</p>
              </div>
              <div className="col-12 col-md-4 text-start text-md-end">
                <p className="small mb-4 mb-md-0">2020 - 2022</p>
              </div>
            </div>

            <div className="row small mb-4 mb-md-0">
              <div className="col">
                <p className="mb-0 mb-md-2">Phi Theta Kappa Honor Society</p>
              </div>
              <div className="col-12 col-md text-start text-md-end">
                <p className="mb-0 mb-md-2">2021 - present</p>
              </div>
            </div>
            <div className="row small mb-4 mb-md-0">
              <div className="col">
                <p className="mb-0 mb-md-2">National Technical Honor Society</p>
              </div>
              <div className="col-12 col-md text-start text-md-end">
                <p className="mb-0 mb-md-2">2021 - present</p>
              </div>
            </div>
            <hr style={{ background: 'gray' }} />
            {[{ name: 'Spring 2022 Dean\'s List', date: 'June 2022' },
            { name: 'Outstanding Program Student Award', date: 'March 2022' },
            { name: 'Fall 2021 Dean\'s List', date: 'January 2022' },
            { name: 'Spring 2021 Dean\'s List', date: 'June 2021' },
            { name: 'Fall 2020 Dean\'s List', date: 'Janurary 2021' }]
              .map(({ name, date }, index) => (
                <div className="row small mb-4 mb-md-0" key={index}>
                  <div className="col">
                    <p className="mb-0 mb-md-2">{name}</p>
                  </div>
                  <div className="col-12 col-md text-start text-md-end">
                    <p className="mb-0 mb-md-2">{date}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <p className="small mt-5 mb-4">
        <Icon name="certificate" className="me-2" /> Certifications
      </p>

      <div className="row">
        <FancyBox options={config.fancybox}>
          {config.certificates.map(([name, img, date], index) => (
            <Certificate index={index} name={name} img={img} date={date} />
          ))}
        </FancyBox>
      </div>
    </div>
  )
}

export default About