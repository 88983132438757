export const logo_1mb = require('./1mb.png');
export const logo_blossom = require('./blossom.png');
export const logo_campfire = require('./campfire.png');
export const logo_crinkle = require('./crinkle.png');
export const logo_domainkit = require('./domainkit.png');
export const logo_hexagonal = require('./hexagonal.png');
export const logo_mtnRd = require('./mtn-rd.png');
export const logo_outage = require('./outage.png');
export const logo_rocketfuel = require('./rocketfuel.png');
export const logo_shopflow = require('./shopflow.png');
export const logo_sourceview = require('./sourceview.png');
export const logo_stackpin = require('./stackpin.png');