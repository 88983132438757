import { config } from "../config";
import { Icon, NewTab } from "../components";
import { useTitle } from "../hooks/useTitle";

function Contact() {

  useTitle('Contact');

  return (
    <div className="container">

      <h1 className="text-center ni heading">Contact Austin</h1>

      <div className="card mt-5">
        <div className="m-4 m-md-5">

          <p className="text-white">I'd love to hear from you! Please complete the following
            contact form or reach out at one of my alternate contacts with inquiries and
            ideas. 👇</p>

          <form className="pt-2" action="https://formsubmit.co/fc50331ed6d2051741a66979e055804a"
            method="post">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="name" className="text-white">Name</label>
                <input type="text" className="form-control" id="name" name="name"
                  placeholder="Enter your name" required />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="text-white">Email</label>
                <input type="email" className="form-control" id="email" name="email"
                  placeholder="Enter your email" required />
              </div>
              <div className="col-md-12 mb-3">
                <label htmlFor="message" className="text-white">Message</label>
                <textarea className="form-control" id="message" name="message"
                  placeholder="Enter your email" rows="7" required />
              </div>
            </div>

            <input type="hidden" name="_honey" />
            <input type="hidden" name="_captcha" value="false" />
            <input type="hidden" name="_next" value="https://dudzik.dev/thanks" />

            <button type="submit" className="btn-gradient w-100 ni py-2 submit-button text-white">
              <Icon name="paper-plane" className="me-2" /> Send message
            </button>
          </form>

          <h4 className="text-white mt-4">Other ways to connect...</h4>

          <div className="row mt-3">
            <div className="col-md mb-2 mb-md-0">
              <NewTab link="tel:4143785182" className="text-white text-decoration-none">
                <Icon name="phone" className="me-2" /> (414) 378-5182
              </NewTab>
            </div>
            <div className="col-md mb-2 mb-md-0">
              <NewTab link={`mailto:${config.social.email}`} className="text-white text-decoration-none">
                <Icon name="envelope" className="me-2" /> austin@dudzik.dev
              </NewTab>
            </div>
            <div className="col-md mb-2 mb-md-0">
              <NewTab link={config.social.linkedin} className="text-white text-decoration-none">
                <Icon type="fab" name="linkedin" className="me-2" /> austin-dudzik
              </NewTab>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Contact