import { Link } from 'react-router-dom';
import Image from '../../hooks/Image';

function MiniProject({ project: { name, slug, icon, cover, short_desc }, index }) {
    return (
        <div className="col-md-4 mb-3" key={index}>
            <Link to={`/projects/${slug}`}
                className="card p-0 h-100">
                <img
                    src={cover}
                    className="img-fluid w-100 rounded-top"
                    alt={`Cover of ${name}`}
                />
                <img
                   height="133px"
                    src={cover}
                    className="img-fluid w-100 rounded-top"
                    alt={`Cover of ${name}`}
                />
                <div className="d-flex justify-content-center">
                    <img
                        width="75"
                        height="75"
                        src={icon}
                        style={{ marginTop: '-36px' }}
                        alt={`Icon of ${name}`}
                    />
                </div>
                <div className="p-4 text-center pt-3">
                    <div className="title">{name}</div>
                    <div className="subtitle text-muted">{short_desc}</div>
                </div>
            </Link>
        </div>
    )
}

export default MiniProject