function Testimonial({ name, company, title, quote, color, props }) {
    return (
        <div className="ps-2 rounded ni" style={{ background: color }} {...props}>
            <div className="testimonial mb-4 ni">
                <blockquote className="mb-3 ps-0 pe-0 pe-md-3">
                    "{quote}"
                </blockquote>
                <p className="fw-500 mb-0">{name}</p>
                <p className="text-muted mb-0">{title} @ {company}</p>
            </div>
        </div>
    )
}

export default Testimonial