export const lirwc_icon = require('./icon.png');
export const lirwc_01 = require('./01.png');
export const lirwc_02 = require('./02.png');
export const lirwc_03 = require('./03.png');
export const lirwc_04 = require('./04.png');
export const lirwc_05 = require('./05.png');
export const lirwc_06 = require('./06.png');
export const lirwc_07 = require('./07.png');
export const lirwc_08 = require('./08.png');
export const lirwc_09 = require('./09.png');
export const lirwc_10 = require('./10.png');
export const lirwc_11 = require('./11.png');