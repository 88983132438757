function HomeSection({ lineOne, lineTwo, subheading, children }) {
    return (
        <section className="my-5">
            <h2 className="subheading mt-5 mb-3 fw-900">{lineOne}<br /><span
                className="text-muted">{lineTwo}</span></h2>
            <p className="text mt-0 mb-5">{subheading}</p>
            {children}
        </section>
    )
}

export default HomeSection