import { Fragment } from 'react'

function Certificate({ name, img, date, index }) {
    return (
        <Fragment key={index}>
            <div className="col-md-6 mb-3">
                <div className="d-flex">
                    <button className="link-btn" data-src={img} data-fancybox="certifications">
                        <img src={img}
                            alt="" className="img-fluid rounded"
                            style={{ height: '50px' }} />
                    </button>
                    <div className="ms-3">
                        <h6 className="mb-1">{name}</h6>
                        <p className="text-muted mb-0">{date}</p>
                    </div>
                </div>
            </div>
            {(index % 2 !== 0) ? <hr /> : <hr className="d-block d-md-none" />}
        </Fragment>
    )
}

export default Certificate