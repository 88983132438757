import { useState, createRef, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css'

import Ratio from 'react-ratio';

function Image({ src, alt, height, width, className, ratio }) {

    const [loading, setLoading] = useState(true);

    const image = createRef();

    console.log(image)

    return (
        <>
            {loading && (
                <Ratio ratio={ratio} style={{ height: height, width: width }}>
                    <Skeleton
                        className={className}
                        baseColor="#2c2c2c"
                        highlightColor="#303035"
                    />
                </Ratio>
            )}
            <div style={{ display: loading ? "none" : "initial" }}>
                <Ratio ratio={ratio} style={{ height: height, width: width }}>
                    <img
                        ref={image}
                        src={src}
                        alt={alt}
                        className={className}
                        onLoad={(prev) => setLoading(!prev)}
                        //height={height}
                        width={width}
                    />
                </Ratio>
            </div>
        </>
    )
}

export default Image;