import { useTitle } from '../hooks/useTitle'

function Skills() {

  useTitle('Skills');

  const skills = { 'Industry Knowledge': ['Front-end Development', 'Marketing', 'Logo Design', 'Sales', 'Digital Media', 'Illustration', 'Image Editing', 'Web Design', 'IT Operations', 'SEO'], 'Tools': ['CloudFlare', 'GitHub', 'Figma', 'Microsoft Word', 'Microsoft Excel', 'Microsoft PowerPoint', 'Microsoft Outlook', 'Adobe Dreamweaver', 'Google Analytics', 'WordPress', 'Adobe XD', 'Git', 'Adobe Photoshop', 'Adobe Illustrator', 'Bash/Shell', 'Windows', 'Confluence', 'PhpMyAdmin', 'WebStorm', 'PhpStorm', 'Visual Studio', 'DBeaver', 'Windows', 'macOS', 'Ubuntu'], 'Languages & Frameworks': ['HTML', 'CSS', 'JavaScript', 'jQuery', 'Vue.js', 'PHP', 'ASP.NET', 'NoSQL', 'Firebase', 'AJAX', 'Oracle SQL', 'C#', 'MySQL', 'Laravel', 'React', 'Sass', 'JSON', 'WordPress', 'Bootstrap', 'Tailwind', 'Vuetify', 'MUI', 'styled-components'], 'Interpersonal Skills': ['Communication', 'Leadership', 'Problem Solving', 'Management', 'Critical Thinking', 'Time Management', 'Self-Motivation', 'Adaptability', 'Teamwork'] }

  return (
    <div>

      <div className="container">

        <div className="text-start text-md-center mt-5">
          <h1 className="mb-3 fw-900 mt-0">Skills</h1>
          <p className="text mt-0 text-muted mb-0">After years of developing, I've established a strong,
            flexible, and growing skill set in dozens of development languages, tools, and
            frameworks, ranging from database management to UI and prototyping.</p>
        </div>

        {Object.keys(skills).map((category, index) => (
          <div key={index}>
            <hr className="my-4 my-md-5" />
            <div className="mx-1 mx-md-4">
              <h3 className="mb-4">{category}</h3>
              <div className="row">
                {skills[category].map((skill, index) => (
                  <div className="col-md-4 mb-3" key={index}>
                    <div className="d-flex">
                      <div className="me-2">✅&nbsp;</div>
                      {skill}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

      </div>
    </div>



  )
}

export default Skills