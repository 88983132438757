import { useTitle } from '../hooks/useTitle'
import { Link } from 'react-router-dom';
import { Icon } from '../components';

function Thanks() {

    useTitle('Thank you');

    return (
        <div className="container text-start text-md-center" id="thanks">
            <h1 className="gradient-heading ni mb-4">Thank you!</h1>
            <p className="mb-3">Your message has arrived safe and sound in my inbox.</p>
            <p className="mt-0">I'll get back to you as soon as possible. Talk soon!</p>
            <Link to="/" className="submit-button">
                Continue to homepage <Icon type="far" name="long-arrow-right" className="ms-2" />
            </Link>
        </div>
    )
}

export default Thanks