export const dune_icon = require('./icon.png');
export const dune_01 = require('./01.png');
export const dune_02 = require('./02.png');
export const dune_03 = require('./03.png');
export const dune_04 = require('./04.png');
export const dune_05 = require('./05.png');
export const dune_06 = require('./06.png');
export const dune_07 = require('./07.png');
export const dune_08 = require('./08.png');
export const dune_09 = require('./09.png');
export const dune_10 = require('./10.png');
export const dune_11 = require('./11.png');
export const dune_12 = require('./12.png');
export const dune_13 = require('./13.png');
export const dune_14 = require('./14.png');