import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

// Import pages
import App from './App';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Experience from './pages/Experience';
import Projects from './pages/Projects';
import Resume from './pages/Resume';
import Skills from './pages/Skills';
import NotFound from './pages/NotFound';
import Thanks from './pages/Thanks';
import SingleProject from './pages/SingleProject';
import ComingSoon from './pages/ComingSoon';

const root = ReactDOM.createRoot(document.getElementById('root'));

function PassDown() {

  const [hideShadow, setHideShadow] = useState(false);
  const ready = true;

  return (
    <>
      <Routes>

        {/* Full layout routes */}
        <Route element={<App hideShadow={hideShadow} ready={ready} />}>
          <Route index element={<Home setHideShadow={setHideShadow} />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="experience" element={<Experience />} />
          <Route path="projects" element={<Projects />} />
          <Route path="resume" element={<Resume />} />
          <Route path="skills" element={<Skills />} />
          <Route path="projects/:slug" element={<SingleProject />} />
        </Route>

        {/* Navigation-only routes */}
        <Route element={<App hideShadow={hideShadow} navOnly />}>
          <Route path="thanks" element={<Thanks />} />
          <Route path="*" element={<NotFound />} status={404} />
        </Route>

        {/* No layout routes */}
        <Route element={<App hideShadow={hideShadow} simple />}>
          <Route path="coming-soon" element={<ComingSoon />} />
        </Route>

      </Routes>
    </>
  );
}

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <PassDown />
  </BrowserRouter>
);