import { useState, useEffect } from 'react';
import { config } from '../config';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTitle } from '../hooks/useTitle';
import { Icon, NewTab } from '../components';
import MiniProject from '../components/blocks/MiniProject';
import NotFound from './NotFound';
import FancyBox from '../hooks/Fancybox';
import Modal from 'react-bootstrap/Modal';

import { AspectRatio } from 'react-aspect-ratio';
import 'react-aspect-ratio/aspect-ratio.css'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Image from '../hooks/Image';

function SingleProject() {

    const navigate = useNavigate();

    const { slug } = useParams();

    const [project, setProject] = useState({});

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {

        const currentProject = config.projects.find(project => project.slug === slug);

        if (currentProject) {
            setProject(config.projects.find(project => project.slug === slug));
        } else {
            setProject(null);
        }

    }, [slug, project, navigate]);

    useTitle(project && project.name);

    const notThis = config.projects.filter(project => project.slug !== slug);

    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;
        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    return (
        <>
            {project ? (
                <div className="container" id="page">

                    <div className="row">
                        <div className="col-12 col-md mb-2 mb-md-0">
                            <Link to="/projects" className="text-white text-decoration-none mb-4">
                                <Icon type="far" name="long-arrow-left" className="me-2" /> Back to all projects
                            </Link>
                        </div>
                        <div className="col-12 col-md text-start text-md-end">
                            <Link to="/projects" className="text-white text-decoration-none">Projects</Link> / <span className="text-muted">{project.name}</span>
                        </div>
                    </div>

                    <div className="card p-0 mt-4">

                        <Image src={project.cover} className="w-100 rounded-top" alt="Cover of " ratio="16/9" height="400px" />

                        <Image src={project.icon} className="ms-4 ms-md-5" alt="Icon of " ratio="1" height="100px" width="100px" style={{ marginTop: '-40px' }} />

                        <div className="m-4 mt-0 m-md-5 mt-md-4">
                            <div className="row">
                                <div className="col-12 col-md mb-3 mb-md-0">
                                    <h3 className="my-0 text-white">{project.name}</h3>
                                    <div className="subtitle">{project.short_desc}</div>
                                </div>

                                <div className="col-12 col-md text-start text-md-end">
                                    {project.github && (
                                        <NewTab link={project.github} className="button alt ms-0 me-2">
                                            <Icon type="fab" name="github" />
                                        </NewTab>
                                    )}
                                    {project.url !== "" && project.protected && (
                                        <>
                                            <button className="button bg-white text-dark alt ms-0" onClick={handleShow}>
                                                Visit project <Icon name="arrow-up-right-from-square" className="ms-2" />
                                            </button>

                                            <Modal
                                                show={show}
                                                onHide={handleClose}
                                                contentClassName="bg-gradient p-1 w-100 rounded"
                                                centered
                                            >
                                                <div>
                                                    <Modal.Body className="text-center bg-black py-5 rounded">
                                                        <span className="fa-stack fa-2x mb-3">
                                                            <Icon name="square" className="fa-stack-2x" />
                                                            <Icon name="key-skeleton" className="text-dark fa-stack-1x fa-inverse" />
                                                        </span>
                                                        <h3 className="mt-0">Project Access</h3>
                                                        <p className="mb-2"><strong>Email/Username:</strong> <code className="text-muted">demo@dudzik.dev</code></p>
                                                        <p><strong>Password:</strong> <code className="text-muted">passw0rd123</code></p>
                                                        <NewTab link={project.url} className="button bg-white text-dark">
                                                            Continue to project  <Icon name="arrow-up-right-from-square" className="ms-2" />
                                                        </NewTab>
                                                    </Modal.Body>
                                                </div>
                                            </Modal>
                                        </>

                                    )}
                                    {project.url !== "" && !project.protected && (
                                        <NewTab link={project.url} className="button bg-white text-dark alt ms-0">
                                            Visit project <Icon name="arrow-up-right-from-square" className="ms-2" />
                                        </NewTab>
                                    )}
                                </div>

                            </div>

                            <h4 className="text-white mt-4">Description</h4>
                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: project.description }} />

                            <div className="bg-gradient text-white p-4 rounded">
                                <h4 className="mb-4">
                                    <Icon type="far" name="layer-group" className="me-2" /> Tech Stack
                                </h4>

                                <div className="row">
                                    {(project.lang ?? []).map((lang, index) => (
                                        <div className="col-md-4 mb-2" key={index}>
                                            <Icon type="fal" name="asterisk" className="me-2" /> {lang}
                                        </div>
                                    ))}
                                </div>

                            </div>

                            <h4 className="text-white mt-4">Screenshots</h4>
                            <div className="row">
                                <FancyBox options={config.fancybox}>
                                    {(project.screenshots ?? []).map(([img, caption], index) => (
                                        <div className="col-6 col-md-4 mb-4" key={index}>
                                            <button className="link-btn" data-src={img} data-fancybox="screenshots" data-caption={caption}>
                                                <img src={img} className="img-fluid rounded" alt={`Screenshot of ${project.name}`} />
                                            </button>
                                        </div>
                                    ))}
                                </FancyBox>
                            </div>

                        </div>
                    </div>

                    <h4 className="text-white mb-4 mt-4">Explore other projects...</h4>

                    <div className="row">
                        {shuffle(notThis).slice(0, 3).map((project, index) => (
                            <MiniProject project={project} index={index} />
                        ))}
                    </div>
                </div>) : <NotFound />}
        </>
    )
}

export default SingleProject