import { Outlet } from 'react-router-dom';
import "./assets/scss/styles.scss";
import Navigation from './sections/Navigation';
import Footer from './sections/Footer';
import logoFloat from './assets/img/logo-white.svg';

function App({ hideShadow, ready, navOnly = false, simple = false }) {

  return (
    <>
      <div className="gradient-stripe fixed-top ni" />
      <img src={logoFloat} className="logo logo_float" alt="" />
      {(navOnly || !simple) && <Navigation hideShadow={hideShadow} ready={ready} />}
      <Outlet />
      {(!navOnly && !simple) && <Footer />}
    </>
  )
}

export default App;
