import HEADSHOT from '../../assets/img/headshot.jpg';
import { config } from '../../config';
import { Link } from 'react-router-dom';
import { Icon, NewTab } from '../../components';

function Briefer() {
    return (
        <div className="briefer">
            <img src={HEADSHOT} alt="Headshot of Austin Dudzik" />
            <div className="fw-500 mt-4" style={{ fontSize: '18px' }}>Austin Dudzik</div>
            <div className="fw-500 text-muted">Front-end Web Developer</div>
            <p className="px-0 px-md-5 mx-0 mx-md-5">I strive to create positive user experiences focused on consistency and
                usability that remain responsive and capture true
                potential.</p>

            <div className="mt-4 mb-3 text-center">
                <NewTab link={config.social.linkedin} className="submit-button">
                    <Icon type="fab" name="linkedin" className="me-2" /> Let's connect
                </NewTab>
                <Link to="/about" className="thin-link mb-2">Learn more about Austin</Link>
            </div>
        </div>
    )
}

export default Briefer