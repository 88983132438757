export const campfire_icon = require('./icon.png');
export const campfire_01 = require('./01.png');
export const campfire_02 = require('./02.png');
export const campfire_03 = require('./03.png');
export const campfire_04 = require('./04.png');
export const campfire_05 = require('./05.png');
export const campfire_06 = require('./06.png');
export const campfire_07 = require('./07.png');
export const campfire_08 = require('./08.png');
export const campfire_09 = require('./09.png');
export const campfire_10 = require('./10.png');
export const campfire_11 = require('./11.png');
export const campfire_12 = require('./12.png');