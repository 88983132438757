import { useEffect, useRef } from 'react';
import WebViewer from '@pdftron/pdfjs-express';
import { Icon, NewTab } from '../components';

import resume from '../assets/pdf/resume.pdf';
import { useTitle } from '../hooks/useTitle';

function Resume() {

  useTitle('Resume');

  const viewer = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: 'pdfjs-express',
        licenseKey: 'V3hK85S4KPnFVCwYclt5',
        initialDoc: resume,
        disabledElements: [
          'leftPanelButton',
          'selectToolButton',
          'panToolButton'
        ]
      },
      viewer.current,
    ).then((instance) => {
      instance.UI.setTheme('dark');
    });
  }, []);

  return (
    <div className="container">
      <div className="text-start text-md-center mt-5">
        <h1 className="mb-3 fw-900 mt-0">Resume</h1>
        <p className="text mt-0 text-muted mb-0">My resume highlights the skills and experience I have developed throughout my years as a front-end web developer and is ever-changing as I continue to divulge into new areas of development and continue to grow my experience day by day.</p>
        <div className="row mt-4">
          <div className="col"></div>
          <div className="col-md-4 mb-3 mb-md-0">
            <a href={resume} download="Austin Dudzik Resume"
              className="btn bg-white py-2 w-100 ni">
              <Icon name="download" className="ms-2" /> Download</a>
          </div>
          <div className="col-md-4">
            <NewTab link={resume} className="btn btn-dark py-2 w-100"><i
              className="fas fa-arrow-up-right-from-square me-2" /> Open in new tab
              </NewTab>
          </div>
          <div className="col"></div>
        </div>
        <p className="mt-4 mb-0 fst-italic">Last updated: March 21st 2022</p>
      </div>
      <hr className="my-5" />
      <div id="viewer" ref={viewer} className="rounded ni w-100 m-0" />
    </div>
  )
}

export default Resume