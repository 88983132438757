import { Link } from 'react-router-dom';

function Project({ name, slug, icon, cover, short_desc, lang, index }) {
    return (
        <div className="col-md-6 mb-4" key={index}>
            <Link to={`/projects/${slug}`} className="card hover p-0">
                <img src={cover}
                    alt={`${name} cover`}
                    className="img-fluid w-100 rounded-top" />
                <img width="75" height="75"
                    src={icon}
                    alt={`${name} icon`} className="icon ms-4" />
                <div className="p-4 pb-5 pt-3 pb-2">
                    <div className="title">{name}</div>
                    <div className="subtitle text-muted mb-2">{short_desc}</div>

                    {lang.slice(0, 3).map((name, index) => (
                        <div className="badge text-white me-1 border-secondary border" key={index}>{name}</div>
                    ))}

                    {lang.length > 3 && (
                        <div className="badge text-white me-1">
                            +{lang.length - 3} more
                        </div>
                    )}
                </div>
            </Link>
        </div>
    )
}

export default Project